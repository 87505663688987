var render = function () {
  var _vm$convertISODateTim;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modalEdit",
    attrs: {
      "id": "reservation-set-auto-issue-modal",
      "title": "\u0110\u1EB7t gi\u1EDD xu\u1EA5t v\xE9 ".concat(_vm.getBookingData.source || _vm.getBookingData.airlines),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-enforce-focus": "",
      "size": "lg"
    },
    on: {
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_vm.alreadyAutoIssueTime ? _c('div', {
          staticClass: "flex-1"
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-danger",
            "pill": "",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
          },
          on: {
            "click": _vm.handleDelete
          }
        }, [_vm._v(" Huỷ đặt giờ xuất vé ")])], 1) : _vm._e(), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0",
          attrs: {
            "pill": "",
            "disabled": !_vm.dataToEdit.autoIssueTime || !_vm.indexSelectedFlightToRecalculatePrice.length && _vm.shouldRecalculatePrice || _vm.resCheckCredit && (_vm.resCheckCredit.isOverLimit || _vm.resCheckCredit.isOverLimitParent)
          },
          on: {
            "click": _vm.submitRecalculatePrice
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('reservation.save')))])])];
      }
    }])
  }, [_c('b-alert', {
    staticClass: "p-1 mb-1 text-title",
    attrs: {
      "show": _vm.getBookingData.source === 'QH',
      "variant": "warning"
    }
  }, [_vm._v(" Do hạn chế từ Bamboo Airways, vui lòng không hẹn giờ xuất vé với code trên 03 khách. ")]), _c('b-alert', {
    staticClass: "p-1 mb-1 text-title",
    attrs: {
      "show": ['VN1A', 'VN1A_MT'].includes(_vm.getBookingData.source),
      "variant": "warning"
    }
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v("Lưu ý Vietnam Airlines: Không hẹn giờ xuất vé qua ngày.")])]), _c('b-card', {
    attrs: {
      "body-class": "py-1"
    }
  }, [_c('b-form-group', {
    staticClass: "margin-checkbox-label mb-0",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-nowrap font-weight-bold"
        }, [_vm._v("Hẹn giờ xuất vé")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticStyle: {
      "width": "50%"
    }
  }, [_c('flat-pickr', {
    attrs: {
      "config": {
        noCalendar: false,
        enableTime: true,
        dateFormat: 'Z',
        time_24hr: true,
        allowInput: true,
        altInput: true,
        disableMobile: true,
        altFormat: 'd-m-Y H:i'
      },
      "placeholder": _vm.convertISODateTime(_vm.minDate).timeDate.replace(' | ', ' ')
    },
    model: {
      value: _vm.dataToEdit.autoIssueTime,
      callback: function callback($$v) {
        _vm.$set(_vm.dataToEdit, "autoIssueTime", $$v);
      },
      expression: "dataToEdit.autoIssueTime"
    }
  })], 1), _c('div', {
    staticClass: "mt-25 text-normal"
  }, [_c('div', {
    class: _vm.afterMaxDate ? 'text-danger' : 'text-secondary'
  }, [_vm._v(" Vui lòng xuất vé trước: " + _vm._s((_vm$convertISODateTim = _vm.convertISODateTime(_vm.maxDate).timeDate) === null || _vm$convertISODateTim === void 0 ? void 0 : _vm$convertISODateTim.replace(" | ", " ")) + " ")])])])], 1), _vm.shouldRecalculatePrice ? _c('div', [_c('b-card', {
    attrs: {
      "body-class": "py-1"
    }
  }, [_c('div', {
    staticClass: "text-heading-5 mb-75"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.selectItineraryToRecalculatePrice')) + " ")]), _c('b-form-group', {
    staticClass: "margin-checkbox-label mb-0",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm.getBookingData.itineraries.length > 1 ? _c('b-form-checkbox', {
          staticClass: "mb-1",
          attrs: {
            "aria-describedby": "flights",
            "aria-controls": "flights",
            "disabled": _vm.isDisabledCheckAllTrip(_vm.getBookingData.itineraries)
          },
          on: {
            "change": _vm.toggleAll
          },
          model: {
            value: _vm.allSelected,
            callback: function callback($$v) {
              _vm.allSelected = $$v;
            },
            expression: "allSelected"
          }
        }, [_c('div', {
          staticClass: "text-body-2"
        }, [_vm._v(" " + _vm._s(_vm.allSelected ? _vm.$t('reservation.unselectAll') : _vm.$t('reservation.selectAll')) + " ")])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref2) {
        var ariaDescribedby = _ref2.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          staticClass: "mb-1",
          attrs: {
            "id": "checkbox-group-cancel-flight",
            "aria-describedby": ariaDescribedby,
            "stacked": "",
            "name": "flights"
          },
          model: {
            value: _vm.indexSelectedFlightToRecalculatePrice,
            callback: function callback($$v) {
              _vm.indexSelectedFlightToRecalculatePrice = $$v;
            },
            expression: "indexSelectedFlightToRecalculatePrice"
          }
        }, _vm._l(_vm.getBookingData.itineraries, function (itinerary, indexItinerary) {
          return _c('div', {
            key: indexItinerary,
            staticClass: "d-flex"
          }, [_c('b-form-checkbox', {
            staticClass: "mb-75",
            attrs: {
              "value": indexItinerary,
              "disabled": _vm.isDisableTripCalc(itinerary)
            }
          }, _vm._l(itinerary, function (segment, indexSegment) {
            return _c('div', {
              key: indexSegment,
              staticClass: "d-flex align-items-center"
            }, [_c('span', {
              staticClass: "text-dark font-weight-bolder"
            }, [_vm._v(" " + _vm._s(_vm.convertShortTrip(segment)))])]);
          }), 0)], 1);
        }), 0)];
      }
    }], null, false, 2624487272)
  })], 1), false ? _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "d-flex-center justify-content-between pt-75 mx-0"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "platingAirline"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v("Airline")])];
      },
      proxy: true
    }], null, false, 2983581495)
  }, [_c('b-form-input', {
    attrs: {
      "id": "platingAirline",
      "disabled": _vm.bookingData.source.includes('1S'),
      "placeholder": _vm.$t('flight.placeholderInput')
    },
    model: {
      value: _vm.platingAirline,
      callback: function callback($$v) {
        _vm.platingAirline = $$v;
      },
      expression: "platingAirline"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mx-sm-1 mx-lg-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "Tour-Code"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v("Tour Code")])];
      },
      proxy: true
    }], null, false, 1482796914)
  }, [_c('b-form-input', {
    attrs: {
      "id": "Tour-Code",
      "placeholder": _vm.$t('flight.placeholderInput'),
      "formatter": _vm.upperCaseFormatter
    },
    model: {
      value: _vm.tourCode,
      callback: function callback($$v) {
        _vm.tourCode = $$v;
      },
      expression: "tourCode"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "Account-Code"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v("Account Code")])];
      },
      proxy: true
    }], null, false, 4042751727)
  }, [_c('b-form-input', {
    attrs: {
      "id": "Account-Code",
      "formatter": _vm.upperCaseFormatter,
      "placeholder": _vm.$t('flight.placeholderInput')
    },
    model: {
      value: _vm.accountCode,
      callback: function callback($$v) {
        _vm.accountCode = $$v;
      },
      expression: "accountCode"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('b-card', {
    attrs: {
      "body-class": "py-1"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "margin-checkbox-label d-flex-center justify-content-around gap-2"
  }, [_c('b-form-checkbox', {
    attrs: {
      "disabled": "",
      "name": "retain-checkbox"
    },
    model: {
      value: _vm.isRetain,
      callback: function callback($$v) {
        _vm.isRetain = $$v;
      },
      expression: "isRetain"
    }
  }, [_c('h5', {
    staticClass: "text-dark mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.retain')) + " ")])])], 1)])])], 1) : _vm._e(), _vm.agencyManager && _vm.agencyManager.id !== 1000000 ? _c('b-card', {
    attrs: {
      "body-class": "py-1"
    }
  }, [_c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.balanceLimit')) + ": ")])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm.resCheckCredit ? _c('span', [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.resCheckCredit.balance)) + " / " + _vm._s(_vm.formatCurrency(_vm.resCheckCredit.limitCredit)) + " ")]) : _c('span', [_vm._v(" 0 ")])])], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.issueTicketPrice')) + ": ")])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.bookingData.totalAmountToBePaid > 0 ? _vm.formatCurrency(_vm.bookingData.totalAmountToBePaid) : 'Vui lòng tính lại giá') + " ")])])], 1), _vm.resCheckCredit ? _c('b-row', [_vm.resCheckCredit.isOverLimitParent ? _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "py-1 text-title my-25",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('reservation.isOverLimitParent', {
        agencyCode: _vm.agencyParentString
      }))
    }
  })])], 1) : _vm._e(), _vm.resCheckCredit.isOverLimit || _vm.resCheckCredit.isWarningLimitPercent ? _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "py-1 text-title mb-0",
    attrs: {
      "show": "",
      "variant": _vm.resCheckCredit.isOverLimit ? 'danger' : 'warning'
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.resCheckCredit.isOverLimit ? _vm.$t('reservation.paymentOverLimit', {
        agencyCode: _vm.agencyString
      }) : _vm.$t('reservation.paymentWarning', {
        agencyCode: _vm.agencyString
      }))
    }
  })])], 1) : _vm.resCheckCredit.isWarning ? _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "py-1 text-title mb-0",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('reservation.paymentWarning', {
        agencyCode: _vm.agencyString
      }))
    }
  })])], 1) : _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "py-1 text-title mb-0",
    attrs: {
      "show": "",
      "variant": "success"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.issueWarning')) + " ")])], 1)], 1) : _vm._e()], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }