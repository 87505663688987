<template>
  <b-modal
    id="reservation-set-auto-issue-modal"
    ref="modalEdit"
    :title="`Đặt giờ xuất vé ${getBookingData.source || getBookingData.airlines}`"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    no-close-on-backdrop
    no-enforce-focus
    size="lg"
    @show="handleShowModal"
  >
    <b-alert
      :show="getBookingData.source === 'QH'"
      variant="warning"
      class="p-1 mb-1 text-title"
    >
      Do hạn chế từ Bamboo Airways, vui lòng không hẹn giờ xuất vé với code trên 03 khách.
    </b-alert>
    <b-alert
      :show="['VN1A', 'VN1A_MT'].includes(getBookingData.source)"
      variant="warning"
      class="p-1 mb-1 text-title"
    >
      <span class="text-danger">Lưu ý Vietnam Airlines: Không hẹn giờ xuất vé qua ngày.</span>
    </b-alert>
    <!-- ANCHOR AutoIssue -->
    <b-card body-class="py-1">
      <b-form-group class="margin-checkbox-label mb-0">
        <template #label>
          <span class="text-nowrap font-weight-bold">Hẹn giờ xuất vé</span>
        </template>
        <div style="width: 50%">
          <flat-pickr
            v-model="dataToEdit.autoIssueTime"
            :config="{
              noCalendar: false,
              enableTime: true,
              dateFormat: 'Z',
              time_24hr: true,
              allowInput: true,
              altInput: true,
              disableMobile: true,
              altFormat: 'd-m-Y H:i'
            }"
            :placeholder="convertISODateTime(minDate).timeDate.replace(' | ', ' ')"
          />
        </div>
        <div class="mt-25 text-normal">
          <div :class="afterMaxDate ? 'text-danger' : 'text-secondary'">
            Vui lòng xuất vé trước: {{ convertISODateTime(maxDate).timeDate?.replace(" | ", " ") }}
          </div>
        </div>
      </b-form-group>
    </b-card>

    <div v-if="shouldRecalculatePrice">
      <!-- ANCHOR Itineraries -->
      <b-card body-class="py-1">
        <div class="text-heading-5 mb-75">
          {{ $t('reservation.selectItineraryToRecalculatePrice') }}
        </div>
        <b-form-group class="margin-checkbox-label mb-0">
          <template #label>
            <b-form-checkbox
              v-if="getBookingData.itineraries.length > 1"
              v-model="allSelected"
              aria-describedby="flights"
              aria-controls="flights"
              :disabled="isDisabledCheckAllTrip(getBookingData.itineraries)"
              class="mb-1"
              @change="toggleAll"
            >
              <div class="text-body-2">
                {{ allSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
              </div>
            </b-form-checkbox>
          </template>
          <template #default="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="checkbox-group-cancel-flight"
              v-model="indexSelectedFlightToRecalculatePrice"
              :aria-describedby="ariaDescribedby"
              stacked
              name="flights"
              class="mb-1"
            >
              <div
                v-for="(itinerary, indexItinerary) in getBookingData.itineraries"
                :key="indexItinerary"
                class="d-flex"
              >
                <b-form-checkbox
                  :value="indexItinerary"
                  class="mb-75"
                  :disabled="isDisableTripCalc(itinerary)"
                >
                  <div
                    v-for="(segment, indexSegment) in itinerary"
                    :key="indexSegment"
                    class="d-flex align-items-center"
                  >
                    <span class="text-dark font-weight-bolder"> {{ convertShortTrip(segment) }}</span>
                  </div>
                </b-form-checkbox>
              </div>
            </b-form-checkbox-group>
          </template>
        </b-form-group>
      </b-card>

      <!-- ANCHOR - Airlines -->
      <!--
        Ẩn nhập tourCode + accountCode khi hẹn giờ xuất vé 1S
        https://discord.com/channels/1054696448110903327/1178223311927390248
      -->
      <b-card
        v-if="false"
        no-body
      >
        <b-row class="d-flex-center justify-content-between pt-75 mx-0">
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group label-for="platingAirline">
              <template #label>
                <span class="text-nowrap">Airline</span>
              </template>

              <b-form-input
                id="platingAirline"
                v-model="platingAirline"
                :disabled="bookingData.source.includes('1S')"
                :placeholder="$t('flight.placeholderInput')"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="mx-sm-1 mx-lg-0"
          >
            <b-form-group label-for="Tour-Code">
              <template #label>
                <span class="text-nowrap">Tour Code</span>
              </template>

              <b-form-input
                id="Tour-Code"
                v-model="tourCode"
                :placeholder="$t('flight.placeholderInput')"
                :formatter="upperCaseFormatter"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group label-for="Account-Code">
              <template #label>
                <span class="text-nowrap">Account Code</span>
              </template>

              <b-form-input
                id="Account-Code"
                v-model="accountCode"
                :formatter="upperCaseFormatter"
                :placeholder="$t('flight.placeholderInput')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!-- ANCHOR - Retain -->
      <b-card body-class="py-1">
        <div class="text-center">
          <div class="margin-checkbox-label d-flex-center justify-content-around gap-2">
            <b-form-checkbox
              v-model="isRetain"
              disabled
              name="retain-checkbox"
            >
              <h5 class="text-dark mb-0">
                {{ $t('reservation.retain') }}
              </h5>
            </b-form-checkbox>
          </div>
        </div>
      </b-card>
    </div>

    <!-- v-if="!isMamaAgency" -->
    <b-card
      v-if="agencyManager && (agencyManager.id !== 1000000)"
      body-class="py-1"
    >
      <b-row class="mb-50">
        <b-col cols="6">
          <span>
            {{ $t('reservation.balanceLimit') }}:
          </span>
        </b-col>
        <b-col
          cols="6"
          class="text-right"
        >
          <span v-if="resCheckCredit">
            {{ formatCurrency(resCheckCredit.balance) }} / {{ formatCurrency(resCheckCredit.limitCredit) }}
          </span>
          <span v-else>
            0
          </span>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col cols="6">
          <span>
            {{ $t('reservation.issueTicketPrice') }}:
          </span>
        </b-col>
        <b-col
          cols="6"
          class="text-right"
        >
          <b>
            {{ bookingData.totalAmountToBePaid > 0 ? formatCurrency(bookingData.totalAmountToBePaid) : 'Vui lòng tính lại giá' }}
          </b>
        </b-col>
      </b-row>

      <!-- ANCHOR - Show Alert -->
      <b-row v-if="resCheckCredit">
        <b-col
          v-if="resCheckCredit.isOverLimitParent"
          cols="12"
          class="text-center"
        >
          <b-alert
            show
            variant="danger"
            class="py-1 text-title my-25"
          >
            <div v-html="$t('reservation.isOverLimitParent', { agencyCode: agencyParentString })" />
          </b-alert>
        </b-col>
        <b-col
          v-if="(resCheckCredit.isOverLimit || resCheckCredit.isWarningLimitPercent)"
          cols="12"
          class="text-center"
        >
          <b-alert
            show
            :variant="(resCheckCredit.isOverLimit) ? 'danger' : 'warning'"
            class="py-1 text-title mb-0"
          >
            <div
              v-html="resCheckCredit.isOverLimit
                ? $t('reservation.paymentOverLimit', { agencyCode: agencyString })
                : $t('reservation.paymentWarning', { agencyCode: agencyString })"
            />
          </b-alert>
        </b-col>
        <b-col
          v-else-if="resCheckCredit.isWarning"
          cols="12"
          class="text-center"
        >
          <b-alert
            show
            variant="warning"
            class="py-1 text-title mb-0"
          >
            <div v-html="$t('reservation.paymentWarning', { agencyCode: agencyString })" />
          </b-alert>
        </b-col>
        <b-col
          v-else
          cols="12"
          class="text-center"
        >
          <b-alert
            show
            variant="success"
            class="py-1 text-title mb-0"
          >
            {{ $t('reservation.issueWarning') }}
          </b-alert>
        </b-col>
      </b-row>
    </b-card>

    <template #modal-footer="{ close }">
      <div
        v-if="alreadyAutoIssueTime"
        class="flex-1"
      >
        <b-button
          variant="outline-danger"
          pill
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleDelete"
        >
          Huỷ đặt giờ xuất vé
        </b-button>
      </div>

      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill width-100"
        @click="close()"
      >
        {{ $t('reservation.close') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        pill
        class="btn-gradient mt-lg-25  border-0"
        :disabled="(!dataToEdit.autoIssueTime) || (!indexSelectedFlightToRecalculatePrice.length && shouldRecalculatePrice) || (resCheckCredit && (resCheckCredit.isOverLimit || resCheckCredit.isOverLimitParent))"
        @click="submitRecalculatePrice"
      >
        <span class="align-middle">{{ $t('reservation.save') }}</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  computed,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api'
import {
  BAlert,
  BButton, BCard,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BModal,
  BRow,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import Vue from 'vue'

import { convertISODateTime, convertShortTrip, formatCurrency } from '@/@core/utils/filter'
import { apiAgencies } from '@/api'
import { getUserData } from '@/api/auth/utils'
import store from '@/store'
import { resolveTitle } from '@/constants/selectOptions'
import IAmPaymentPassword from '@/components/IAmPaymentPassword.vue'
import i18n from '@/libs/i18n'

import { upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'
import { validatorPaymentPassword } from '@core/utils/validations/validators'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    flatPickr,
    BModal,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BButton,
    BCard,
    BFormInput,
    BRow,
    BCol,
    BAlert,
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit, root }) {
    const isMamaAgency = computed(() => store.getters['userStore/getRoleMama'])
    // const agencyManager = computed(() => getUserData().employeeData?.agency)
    const agencyManager = ref(props.bookingData?.createdBy?.agency)
    // const getTotalAmount = computed(() => {
    //   if (props.bookingData) {
    //     return props.bookingData.totalAmountToBePaid
    //   }
    //   return 0
    // })
    const resCheckCredit = ref(null)

    const meData = computed(() => store.getters['userStore/getMeData'])
    const isBookerF1 = computed(() => {
      if (isEmpty(meData.value)) return false
      return meData.value?.agency?.id === 1000000 && meData.value?.type === 'BE'
    })

    const dataToEdit = ref({
      autoIssueTime: props.bookingData.autoIssueTime,
    })
    const alreadyAutoIssueTime = computed(() => !!props.bookingData.autoIssueTime)

    // watch(() => props.bookingData, val => {
    //   console.log(val)
    //   dataToEdit.value = {
    //     autoIssueTime: val.autoIssueTime,
    //   }
    //   alreadyAutoIssueTime.value = !!val.autoIssueTime
    // }, { deep: true, immediate: true })

    const {
      updateAutoIssueTime,
      updateResetIssueTime,
    } = useReservationHandle()

    const minDate = computed(() => new Date())
    const maxDate = computed(() => {
      const times = []
      if (props.bookingData.timeHold) times.push(moment(props.bookingData.timeHold).add({ minute: -20 }))
      if (props.bookingData.historicalPriceQuotes?.[0]?.lastDateToPurchase) {
        times.push(moment(props.bookingData.historicalPriceQuotes?.[0]?.lastDateToPurchase).add({ minute: -20 }))
      }
      if (times.length === 0) {
        times.push(
          moment(props.bookingData.dateCreate).add({ hour: 24 }),
          moment(props.bookingData.itineraries[0]?.[0].departure.at).add({ hour: -3 }),
        )
      }
      const maxTime = times.sort((a, b) => a.isAfter(b))[0]
      return maxTime.toISOString()
    })
    const beforeMinDate = ref(false)
    const afterMaxDate = ref(false)

    async function handleEdit(calcPriceReq) {
      // mat khau thanh toan
      const getMeEnablePaymentPassword = computed(() => store.getters['userStore/getMeEnablePaymentPassword'])
      let paymentPassword
      if (getMeEnablePaymentPassword.value) {
        paymentPassword = await Vue.swal({
          title: root.$t('myAccount.paymentPassword.title'),
          html: '<div id="i-am-payment-password"></div>',
          didOpen: () => {
            new Vue({
              render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'off' } }),
              i18n,
            }).$mount('#i-am-payment-password')
          },
          focusConfirm: false,
          preConfirm: () => new Promise(resolve => {
            const pwd = document.getElementById('swal-input-payment-password-1').value
            if (!validatorPaymentPassword(pwd)) {
              Vue.swal.showValidationMessage(root.$t('myAccount.paymentPassword.errorInput'))
            } else { resolve(pwd) }
          }),
          showCancelButton: true,
          confirmButtonText: root.$t('confirmation'),
          cancelButtonText: root.$t('cancel'),
          buttonsStyling: true,
          customClass: {
            confirmButton: 'btn btn-gradient',
            cancelButton: 'btn btn-danger mr-1',
          },
          allowOutsideClick: false,
          reverseButtons: true,
        })
        if (paymentPassword.isDenied || paymentPassword.isDismissed) {
          return
        }
      }

      store.dispatch('app/setLoading', true)
      await updateAutoIssueTime(props.bookingData.id, {
        autoIssueTime: convertISODateTime(dataToEdit.value.autoIssueTime).ISOdatetime,
        paymentPassword: paymentPassword?.value,
        calcPriceReq,
      })
        .then(() => {
          emit('refetch-data')
        })
        .finally(() => {
          store.dispatch('app/setLoading', false)
        })
    }

    async function handleDelete() {
      store.dispatch('app/setLoading', true)
      await updateResetIssueTime(props.bookingData.id)
        .then(() => {
          emit('refetch-data')
          this.$refs.modalEdit.hide()
        })
        .finally(() => {
          store.dispatch('app/setLoading', false)
        })
    }

    const getBookingData = toRefs(props).bookingData
    const indexSelectedFlightToRecalculatePrice = ref([])
    const indexSelectedFlightToRecalculatePriceComputed = computed(() => indexSelectedFlightToRecalculatePrice.value.sort())
    const isRetain = ref(true)
    const isBargainFinder = ref(false)
    const shouldRecalculatePrice = ref((getBookingData.value.source.includes('1S') || (!['VN1A_MT', 'VN1A'].includes(getBookingData.value.source) && getBookingData.value.airlines.includes('VN'))))

    const paxListsData = ref(cloneDeep(props.bookingData.paxLists))
    const selectedIds = ref(paxListsData.value.map(item => item.paxId))
    const tourCode = ref('')
    const accountCode = ref('')
    const platingAirline = ref('')
    const allPaxsSelected = ref(false)

    watch(() => selectedIds.value, listSelected => {
      if (listSelected.length === paxListsData.value.length) {
        allPaxsSelected.value = true
      } else {
        allPaxsSelected.value = false
      }
    }, { immediate: true })

    async function checkCreditForPayment(agency) {
      const payload = {
        id: agency.id,
        // amount: getTotalAmount.value,
        amount: props.bookingData.totalAmountToBePaid,
      }
      apiAgencies.checkCredit(payload.id, { amount: payload.amount }).then(res => {
        resCheckCredit.value = {
          ...res.data,
          isWarning: false, // ẩn cảnh báo warningLimit
          isWarningLimitPercent: false, // ẩn cảnh báo warningLimit
        }
      })
    }

    // watch(() => [agencyManager.value, getTotalAmount.value], ([agencyData]) => {
    //   if (agencyData) {
    //     console.log({ agencyData })
    //     if (agencyData.id !== 1000000) {
    //       checkCreditForPayment(agencyData)
    //     } else {
    //       resCheckCredit.value = null
    //     }
    //   }
    // }, { immediate: true })

    function handleShowModal() {
      tourCode.value = ''
      accountCode.value = ''
      platingAirline.value = ''
      isBargainFinder.value = false

      if (props.bookingData && props.bookingData.source.includes('1S')) {
        platingAirline.value = 'VN'
      }

      if (agencyManager.value.id !== 1000000) {
        checkCreditForPayment(agencyManager.value)
      } else {
        resCheckCredit.value = null
      }

      indexSelectedFlightToRecalculatePrice.value = []
      paxListsData.value = cloneDeep(props.bookingData.paxLists)
      selectedIds.value = paxListsData.value.map(item => item.paxId)

      isRetain.value = true
    }

    async function submitRecalculatePrice(bvModalEvent) {
      bvModalEvent.preventDefault()

      afterMaxDate.value = moment(dataToEdit.value.autoIssueTime).isAfter(maxDate.value)
      if (afterMaxDate.value) return

      if (!shouldRecalculatePrice.value) {
        handleEdit().then(() => {
          this.$refs.modalEdit.hide()
        })
        return
      }
      if (shouldRecalculatePrice.value) {
        const now = new Date(dataToEdit.value.autoIssueTime)
        const hour = now.getHours()
        const min = now.getMinutes()
        const isLockedTime = hour === 23 && min >= 30
        if (isLockedTime) {
          const titleMsg = () => this.$createElement('div', {
            domProps: { innerHTML: 'Hệ thống Vietnam Airlines khoá thanh toán, hoàn huỷ booking từ 23:30 đến hết 24:00 trong ngày !!!' },
          })
          this.$bvModal
            .msgBoxConfirm(titleMsg(), {
              title: 'Đặt giờ thất bại',
              size: 'sm',
              okVariant: 'info',
              okTitle: 'Đã hiểu',
              cancelTitle: this.$t('reservation.back'),
              cancelVariant: 'outline-secondary',
              hideHeaderClose: true,
              centered: true,
            })
          return
        }
      }

      const paxListArr = paxListsData.value.filter(item => selectedIds.value.includes(item.paxId))

      const payload = {
        contact: getUserData().employeeData.id,
        source: getBookingData.value.source,
        agencyCode: getBookingData.value.agency,
        paxCode: getBookingData.value.paxCode || '',
        pnrNumber: getBookingData.value.bookingCode,
        tourCode: tourCode?.value ? (tourCode?.value).toUpperCase() : '',
        accountCode: accountCode?.value ? (accountCode?.value).toUpperCase() : '',
        platingAirline: platingAirline.value,
        retain: isRetain.value,
        bargainFinder: isBargainFinder.value,
        paxList: paxListArr.map(passenger => ({
          paxId: passenger.paxId,
          seats: passenger.seats || [],
          title: passenger.title || resolveTitle(passenger.firstName.split(' ')[passenger.firstName.split(' ').length - 1]),
          gender: passenger.gender,
          eticket: passenger.eticket || [],
          paxType: passenger.paxType,
          PaxDocuments: passenger.document || [],
          lastName: passenger.lastName,
          firstName: passenger.firstName,
          birthday: passenger.birthday || null,
          bagCheckin: passenger.bagCheckin || [],
          parentPaxId: passenger.parentPaxId,
          pricingInfo: passenger.pricingInfo,
          travelFlyer: passenger.travelFlyer || '',
        })),
        itineraries: getBookingData.value.itineraries.map((trip, indexTrip) => {
          if (!indexSelectedFlightToRecalculatePriceComputed.value.includes(indexTrip)) return
          // eslint-disable-next-line consistent-return
          return {
            source: getBookingData.value.source,
            airline: trip[0].airline || null,
            clientId: getBookingData.value.clientId || '',
            bookingKey: getBookingData.value.bookingKey || '',
            fareBreakdowns: [
              {
                paxType: 'ADULT',
                netFare: 0,
                tax: 0,
                total: 0,
                fareValue: getBookingData.value.fareValue || trip.fareValue || '',
              },
            ],
            segments: trip.map(segment => ({
              leg: segment.leg,
              airline: segment.airline,
              departure: segment.departure.iataCode,
              arrival: segment.arrival.iataCode,
              departureTime: convertISODateTime(segment.departure.at, segment.departure.timeZone).ISOdatetime,
              arrivalTime: convertISODateTime(segment.arrival.at, segment.arrival.timeZone).ISOdatetime,
              flightNumber: segment.flightNumber,
              fareType: segment.fareType || '',
              fareBasisCode: segment.fareBasisCode || '',
              bookingClass: segment.bookingClass || '',
              groupClass: segment.groupClass || '',
              marriageGrp: segment.marriageGrp || '',
              segmentValue: segment.segmentValue || '',
              segmentId: segment.segmentId,
            })),
          }
        }).filter(i => !!i),
      }

      if (!isEmpty(payload.itineraries)) {
        // show modal confirm to modify
        const titleMsg = () => this.$createElement('div', {
          domProps: { innerHTML: this.$t('reservation.payments.confirmReCalcPrice') },
        })
        this.$bvModal
          .msgBoxConfirm(titleMsg(), {
            title: this.$t('modal.confirm'),
            size: 'sm',
            okVariant: 'info',
            okTitle: this.$t('reservation.recalculatePriceFlight'),
            cancelTitle: this.$t('reservation.back'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              handleEdit(payload).then(() => {
                this.$refs.modalEdit.hide()
              })
            }
          })
      }
    }

    // ANCHOR SELECT ALL
    const allSelected = ref(false)

    function toggleAll(checked) {
      indexSelectedFlightToRecalculatePrice.value = checked
        ? getBookingData.value.itineraries.map((item, index) => index).slice()
        : []
    }
    watch(indexSelectedFlightToRecalculatePrice, newValue => {
      if (newValue.length === 0) {
        allSelected.value = false
      } else if (newValue.length === getBookingData.value.itineraries.map((item, index) => index).length) {
        allSelected.value = true
      } else {
        allSelected.value = false
      }
    })

    function setArray(array, id) {
      const index = array ? array.findIndex(element => element === id) : -1
      // eslint-disable-next-line no-unused-expressions
      index >= 0 ? array.splice(index, 1) : array.push(id)

      return array
    }

    function chooseItem(id) {
      selectedIds.value = setArray(selectedIds.value, id)
    }

    function isChecked(id) {
      return selectedIds.value.includes(id)
    }

    function handlePaxsSelectAll(value) {
      if (value) {
        selectedIds.value = paxListsData.value.map(item => item.paxId)
      } else {
        selectedIds.value = []
      }
    }

    const paxListsColumns = [
      { label: '', key: 'checkbox' },
      { label: 'paxId', key: 'pax_id' },
      { label: 'paxType', key: 'pax_type' },
      { label: 'fullName', key: 'full_name' },
    ]
    watch(() => isBargainFinder.value, newValue => {
      if (newValue) {
        allPaxsSelected.value = true
        handlePaxsSelectAll(allPaxsSelected.value)
      }
    })

    function isDisabledCheckAllTrip(trips) {
      if (trips.length === 0) return true
      if (isBookerF1.value) return false // chỉ booker F1 mới tính giá và xuất vé
      return trips.some(trip => trip.some(segment => ['HL'].includes(segment.status)))
    }

    function isDisableTripCalc(trip) {
      if (isBookerF1.value) return false
      return trip.some(segment => ['HL'].includes(segment.status))
    }

    // Hiển thị code(tên) của đai lý khi hết hạn mức
    const agencyParentString = computed(() => {
      const code = getBookingData.value?.createdBy?.agency?.parentAgency?.agencyCode || ''
      const name = ` (${getBookingData.value?.createdBy?.agency?.parentAgency?.agencyName})` || ''
      return `${code}${name}`
    })

    const agencyString = computed(() => {
      const code = getBookingData.value?.createdBy?.agency?.agencyCode || ''
      const name = ` (${getBookingData.value?.createdBy?.agency?.agencyName})` || ''
      return `${code}${name}`
    })

    return {
      isMamaAgency,
      // getTotalAmount,
      resCheckCredit,
      agencyManager,
      isBookerF1,
      isDisabledCheckAllTrip,
      isDisableTripCalc,

      dataToEdit,
      handleEdit,
      handleDelete,
      minDate,
      maxDate,
      beforeMinDate,
      afterMaxDate,

      getBookingData,
      paxListsData,
      paxListsColumns,

      formatCurrency,
      submitRecalculatePrice,
      convertISODateTime,
      indexSelectedFlightToRecalculatePrice,
      indexSelectedFlightToRecalculatePriceComputed,

      toggleAll,
      allSelected,
      isRetain,
      tourCode,
      platingAirline,
      accountCode,
      allPaxsSelected,
      handleShowModal,

      handlePaxsSelectAll,
      isChecked,
      chooseItem,
      upperCaseFormatter,
      isBargainFinder,
      alreadyAutoIssueTime,
      shouldRecalculatePrice,
      convertShortTrip,

      agencyString,
      agencyParentString,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.margin-checkbox-label::v-deep {
  .custom-control-label {
    // vertical-align: center !important;
    margin-top: 2px !important;
  }
}

.custom-v-select-pax-type::v-deep {
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
  }
}
</style>
